<template lang="pug">
    .user-menu
        .transparent(@click="$store.commit('mobile-layout/showUserMenu', false)")
        .wrapper
            .content
                .user(:class="{'is-admin' : isAdmin}")
                    div.user-block(@click.stop.prevent="goTo('/annuaire/' + user.username)")
                        img.img-profile(
                            :src="getUserImageUrl(user.username)",
                            @error="replaceByDefault",
                            v-if="showUserImg"
                        )
                        .user-profile(
                            v-else,
                            :style="'background-color:' + (user.extraproperties && user.extraproperties.color ? user.extraproperties.color : '#FBC0C0')"
                        )
                            p.title-3 {{ user.first_name.substr(0, 1) + user.last_name.substr(0, 1) }}
                        p.first-name.title-5 {{ user.first_name }}
                        p.last-name.title-5 {{ user.last_name }}

                    .section(v-if="isAdmin && $route.meta.layout == 'user'" @click.stop.prevent="goTo('/admin')")
                        img(src="@/assets/icon/user-interface.svg")
                        p.link.title-6 {{ $t('adminInterface') }}
                    .section(v-else-if="isAdmin" @click.stop.prevent="goTo('/home')")
                        img(src="@/assets/icon/user-interface.svg")
                        p.link.title-6 {{ $t('userInterface') }}
                div.menu
                    .links(v-if="$route.meta.layout == 'user'")
                        module-row.module(
                            :urlIcon="homeIcon",
                            :label="$t('home')",
                            link="/home",
                            :isActive="$route.meta.menu == 'home'"
                        )
                        module-row.module(
                            v-if="modules",
                            v-for="(module, uuid) in modules",
                            :urlIcon="module.icon",
                            :key="uuid",
                            :link="$route.meta.layout == 'user' ? module.homeRoute : module.adminRoute",
                            :label="$t(module.name)",
                            :isActive="$route.meta.menu == uuid"
                        )

                    .admin-menu(v-if="$route.meta.layout == 'admin'")
                        module-row.module(
                            :urlIcon="homeIcon"
                            :label="$t('home')"
                            link="/admin"
                            :isActive="$route.meta.menu == 'admin'"
                        )
                        module-row.module(
                            :urlIcon="groupIcon"
                            :label="$t('groups')"
                            link="/admin/groups"
                            :isActive="$route.meta.menu == 'admin-groups'"
                        )
                        module-row.module(
                            :urlIcon="afiIcon"
                            :label="$t('Ask for it')"
                            link="/admin/requests"
                            :isActive="$route.meta.menu == 'admin-requests'"
                        )
                        module-row.module(
                            :urlIcon="articleIcon"
                            :label="$t('articles')"
                            link="/admin/articles"
                            :isActive="$route.meta.menu == 'admin-articles'"
                        )
                        module-row.module(
                            :urlIcon="directoryIcon"
                            :label="$t('directory')"
                            link="/admin/users"
                            :isActive="$route.meta.menu == 'admin-users'"
                        )
                        module-row.module(
                            :urlIcon="gestionIcon",
                            :label="$t('manage')",
                            link="/admin/manage",
                            :isActive="$route.meta.menu == 'admin-manage'"
                        )
                        module-row.module(
                            :urlIcon="kpiIcon",
                            :label="$t('kpi')",
                            link="/admin/kpi",
                            :isActive="$route.meta.menu == 'admin-kpi'")

                    .helps
                        module-row.module(
                            urlIcon="/img/icon/help-icon.svg",
                            :label="$t('help')",
                            link="/help",
                            :isActive="$route.meta.menu == 'help'"
                        )
                        module-row.module(
                            urlIcon="/img/icon/language.svg",
                            :label="$t('language')",
                            :link="languageCallback()",
                            :isActive="false"
                        )
                        module-row.module(
                            urlIcon="/img/icon/logout.svg",
                            :label="$t('logout')",
                            :link="logoutCallback()",
                            :isActive="false"
                        )

</template>

<script lang="ts">
import Vue from "vue";

import UserService from "skillbase-component/src/components/manage-user/services/UserService";
import ModulesLib from "@/applicationSettings/Modules.js";
import HomeIcon from "@/assets/icon/home-icon.svg";
import GestionIcon from 'skillbase-component/src/components/common/icons/layout/gestion.svg'
import DirectoryIcon from "@/assets/modules/directory-icon.svg"
import AfiIcon from '@/assets/modules/afi-icon.svg'
import GroupIcon from '@/assets/modules/group-icon.svg'
import ArticleIcon from '@/assets/modules/article-icon.svg'
import KPIIcon from '@/assets/modules/kpi.svg'

export default Vue.extend({
    data() {
        return {
            modules: null,
            showUserImg: true,

            homeIcon: HomeIcon,
            gestionIcon: GestionIcon,
            directoryIcon: DirectoryIcon,
            afiIcon: AfiIcon,
            groupIcon: GroupIcon,
            articleIcon: ArticleIcon,
            kpiIcon: KPIIcon
        };
    },

    components: {
        ModuleRow: () => import("./ModuleRow.vue"),
    },

    computed: {
        user() {
            return this.$store.getters["user-profile/getUser"](
                this.$store.getters.user.username
            );
        },

        username() {
            return this.user ? this.$store.getters.user.username : "";
        },

        isAdmin() {
            return this.$store.getters['acl/isAdmin'];
        },
    },

    methods: {
        getUserImageUrl(username) {
            return UserService.getUserImageUrl(username) + "?" + Date.now();
        },
        replaceByDefault(e) {
            this.showUserImg = false;
            this.$forceUpdate();
        },
        goTo(url) {
            this.$store.commit("search/resetWrapper")
            this.$store.commit('mobile-layout/showUserMenu', false)
            this.$router.push(url).catch((err) => {
            });
        },
        languageCallback() {
            return {
                cb: () => {
                    this.$store.commit('mobile-layout/showUserMenu', false)
                    this.$store.commit('mobile-layout/showLanguageView', true)
                }
            }
        },
        logoutCallback() {
            return {
                cb: () => {
                    this.$store.dispatch("logout").then(() => {
                        this.$router.push("/login");
                    });
                }
            }
        }
    },

    mounted() {
        this.getUserImageUrl(this.$store.getters.user.username);
        this.$store.dispatch('user-profile/fetchUser', this.$store.getters.user.username)
        this.$store.dispatch("acl/updateUserRole")

        ModulesLib.getModules().then((modules) => {
            this.modules = modules;
        });
    }
});
</script>

<style lang="scss" scoped>
.user-menu {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 3000;

    .transparent {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0px;
        left: 0px;
        background-color: var(--modal-overlayer-color);
        z-index: 3001;
    }

    .wrapper {
        position: fixed;
        width: 80vw;
        height: 100vh;
        top: 0px;
        left: 0px;
        opacity: 1;
        background-color: white;
        z-index: 3002;

        .menu {
            height: calc(100vh - 170px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .content {
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            .is-admin {
                height: 167px !important;
            }

            .user {
                height: 110px;
                background-color: var(--background-color);

                .img-profile {
                    width: 50px;
                    height: 50px;
                    top: 32px;
                    left: 32px;
                    position: absolute;
                    border-radius: 100%;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .user-profile {
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    border-radius: 100vh;
                    top: 32px;
                    left: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;

                    p {
                        text-transform: uppercase;
                        color: white;
                        text-align: center;
                    }
                }

                .first-name {
                    position: absolute;
                    top: 37px;
                    left: 103px;
                }

                .last-name {
                    position: absolute;
                    top: 55px;
                    left: 103px;
                }

                .section {
                    padding-top: 114px;
                    padding-left: 37px;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 21px;
                    }
                }
            }

            .helps {
                bottom: 0px;
                width: 80vw;
                border-top: 2px solid var(--lighter-2-text-color);
                padding-bottom: 15px;
            }
        }
    }

    @media only screen and (max-width: 700px) {
        .helps {
            position: fixed !important;
            bottom: 0px;
            width: 80vw;
        }
        .links, .admin-menu {
            padding-bottom: 20px;
        }
    }

}
</style>